import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Box, Text, Link, useBreakpointValue, HStack, VStack } from '@chakra-ui/react'
import { getHrefFromCategory, groupCategories, limitChildren } from 'atg/utils'
import { NextLinkNoPrefetch, useMegaMenu, AccountFormTypes, MegaMenuDetailsBar } from 'composable'
import { AnimatePresence, motion } from 'framer-motion'
import { utagLink } from 'helpers/tealiumHelper'
import { useAtgUser } from 'frontastic/contexts'
import { transformMegaMenuData } from '../../utils'

interface MegaMenuProps {
  megaMenuData: Category[]
}

export interface Category {
  displayName: string
  availableForInStoreOrderEntry: boolean
  hideFromMegaMenu?: boolean
  sortOrder?: number
  fixedChildCategories: Category[]
  seoUrlKeywords: string
  id: string
  level?: number
  navlinkColor?: string
  alternateUrl?: string
}

const AtgMegaMenuDesktop: React.FC<MegaMenuProps> = ({ megaMenuData }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const [accountFormToShow, setAccountFormToShow] = useState<AccountFormTypes>()

  if (isMobile) {
    const DynamicMegaDrawer = dynamic(() => import('../legacy-mega-drawer').then((el) => el.MegaDrawer))
    const legacyItems = transformMegaMenuData(megaMenuData)
    return (
      <DynamicMegaDrawer
        items={legacyItems}
        accountFormToShow={accountFormToShow}
        setAccountFormToShow={setAccountFormToShow}
      />
    )
  }

  return (
    <Box
      as="nav"
      id="mega-menu"
      aria-label="Mega Menu"
      position="relative"
      zIndex="3"
      display={{ base: 'none', md: 'flex' }}
      flexDirection="column"
      alignItems="center"
      maxW="container.2xl"
      mx="auto"
      lineHeight={'19.5px'}
      borderBottom={'1px solid black'}
    >
      <Box width={'100%'} mt={2} display="flex">
        {/* <SkipNavigationButton /> */}
        <HStack alignItems="stretch" gap={0} rowGap={0} mx="auto" width="100%" justifyContent={'space-between'}>
          {megaMenuData?.map((item) => (
            <LegacyMegaMenuItem key={item.id} {...item} />
          ))}
        </HStack>
      </Box>
    </Box>
  )
}

function LegacyMegaMenuItem(item: Category) {
  const router = useRouter()
  const href = getHrefFromCategory(item)
  const hasChildren = item.fixedChildCategories.length > 0
  const children = item.fixedChildCategories as any
  const { userSessionData } = useAtgUser()
  const { linkProps, rootProps, motionProps, renderContent, childrenLinkProps } = useMegaMenu({
    item: {
      href,
      hasChildren,
      children,
    },
    routerPush: router.push,
  })

  const utagLinkEvent = (navLink, navLinkUrl) => {
    utagLink(
      [
        'link',
        {
          tealium_event: 'nav_meganav',
          nav_link: navLink,
          nav_link_url: navLinkUrl,
        },
      ],
      userSessionData?.dxlCountry,
      userSessionData?.dxlCurrency,
    )
  }

  const categoryGroups = groupCategories(children, 8)

  return (
    item && (
      <Box {...rootProps}>
        <Link
          as={NextLinkNoPrefetch}
          href={item.alternateUrl ?? href}
          onFocus={linkProps?.onFocus}
          display="block"
          boxSizing="border-box"
          textUnderlineOffset={5}
          textDecorationColor={item?.navlinkColor || 'text'}
          aria-label={item.displayName}
          {...linkProps}
          onClick={() => {
            utagLinkEvent(item.displayName, item.alternateUrl ?? href)
          }}
        >
          <Text
            // whiteSpace="nowrap"
            textColor={item?.navlinkColor || 'text'}
            textDecorationColor={item?.navlinkColor || 'text'}
            fontSize="14px"
            px="6px"
            pb="10px"
            fontWeight={400}
          >
            {item.displayName?.replace('&a;', '&').toUpperCase()}
          </Text>
        </Link>
        <AnimatePresence>
          {renderContent && (
            <motion.div {...motionProps} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Box
                alignSelf={'stretch'}
                background="white"
                boxShadow={'0px 8px 16px -8px rgba(43, 49, 57, 0.20)'}
                bgColor={'#fafafa'}
                px={10}
                py={10}
              >
                <HStack spacing={8} width="100%" gap={8} alignItems="start">
                  {categoryGroups.map((column, idx) => (
                    <VStack spacing={4} key={`col-${idx}`} minW={56} alignItems={'left'}>
                      {column.map((category, idx) => (
                        <Box key={`row-${idx}`}>
                          <Link
                            aria-label={category.displayName}
                            href={getHrefFromCategory(category)}
                            fontSize="14px"
                            fontWeight={700}
                            {...childrenLinkProps}
                            onClick={() => {
                              utagLinkEvent(category.displayName, getHrefFromCategory(category))
                            }}
                          >
                            {category.displayName}
                          </Link>
                          {category?.fixedChildCategories?.length > 0 && (
                            <VStack alignItems="left" mt={1} gap={2}>
                              {limitChildren(category.fixedChildCategories, 8).map((childCategory) => (
                                <Link
                                  aria-label={childCategory.displayName}
                                  key={childCategory.id}
                                  href={getHrefFromCategory(childCategory)}
                                  fontSize="14px"
                                  fontWeight={400}
                                  {...childrenLinkProps}
                                  onClick={() => {
                                    utagLinkEvent(childCategory.displayName, getHrefFromCategory(childCategory))
                                  }}
                                >
                                  {childCategory.displayName}
                                </Link>
                              ))}
                            </VStack>
                          )}
                        </Box>
                      ))}
                    </VStack>
                  ))}
                </HStack>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    )
  )
}

function SkipNavigationButton() {
  const [skipNavigationOpacity, setSkipNavigationOpacity] = useState<number>(0)
  return (
    <Link
      tabIndex={0}
      top="-22px"
      left="30px"
      onFocus={() => setSkipNavigationOpacity(1)}
      onBlur={() => setSkipNavigationOpacity(0)}
      opacity={skipNavigationOpacity}
      position="absolute"
      zIndex={-1}
      onClick={() => setSkipNavigationOpacity(0)}
      href="#"
      textDecoration="none"
    >
      <Text
        aria-label="Skip Navigation"
        zIndex={-1}
        textAlign="left"
        padding="3px 6px"
        background="#EDEDED"
        right="20px"
        border="1px solid"
      >
        Skip Navigation
      </Text>
    </Link>
  )
}

export default AtgMegaMenuDesktop
