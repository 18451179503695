import { Category } from 'atg/components/mega-menu'
import { Entry } from 'composable/components/hooks/useContentstack'

export function limitChildren(children: Category[], limit: number): Category[] {
  return children.slice(0, limit)
}

export function getHrefFromCategory(category: Category): string {
  if (category.alternateUrl) {
    return category.alternateUrl
  } else {
    return `/${category.seoUrlKeywords}/${category.id}`
  }
}

export function getHrefFromTransformedEntryData(entry: Entry): string {
  if (entry.alternateUrl) {
    return entry.alternateUrl
  } else {
    return `/${entry.href}/${entry.uid}`
  }
}

function countItemsInCategory(category: Category): number {
  return category.fixedChildCategories
    ? 1 + category.fixedChildCategories.reduce((count, childCategory) => count + countItemsInCategory(childCategory), 0)
    : 1
}

/**
 * Splits ATG Mega Menu data into groups of categories such that each group has
 * at most `maxItems` items, allowing for a more balanced layout in the Mega Menu.
 * @param categories
 * @param maxItems
 * @returns
 */
export function groupCategories(categories: Category[], maxItems: number = 8): Category[][] {
  const groups: Category[][] = []
  let currentGroup: Category[] = []
  let currentItemCount = 0

  for (const category of categories) {
    const itemCount = countItemsInCategory(category)
    if (itemCount > maxItems) {
      // If current group has items, push it to groups
      if (currentGroup.length > 0) {
        groups.push(currentGroup)
        currentGroup = []
      }
      // Place large category in its own group
      groups.push([category])
      currentItemCount = 0 // Reset item count after pushing
    } else if (currentItemCount + itemCount > maxItems) {
      // If adding this category exceeds max, push current group and start a new one
      groups.push(currentGroup)
      currentGroup = [category]
      currentItemCount = itemCount
    } else {
      // Add category to current group
      currentGroup.push(category)
      currentItemCount += itemCount
    }
  }

  // Push the last group if it has items
  if (currentGroup.length > 0) {
    groups.push(currentGroup)
  }

  return groups
}

/**
 * Transform From ATG Mega Menu Data to Contentstack Mega Menu Data
 * only required if rendering ATG Mega Menu with Contentstack Component
 * @param data
 * @returns
 */
export function transformMegaMenuData(data: Category[]): Entry[] {
  return data?.map((item) => {
    const displayName = item.displayName.replace('&a;', '&').toUpperCase()
    return {
      uid: item.id,
      title: displayName,
      label: displayName,
      href: item.seoUrlKeywords,
      alternateUrl: item.alternateUrl ?? null,
      description: displayName,
      imagesCollection: null,
      children: item.fixedChildCategories.map((child) => {
        return {
          uid: child.id,
          title: child.displayName,
          label: child.displayName,
          href: child.seoUrlKeywords,
          alternateUrl: child.alternateUrl ?? null,
          description: child.displayName,
          imagesCollection: null,
          children: child.fixedChildCategories.map((subChild) => {
            return {
              uid: subChild.id,
              title: subChild.displayName,
              label: subChild.displayName,
              alternateUrl: subChild.alternateUrl ?? null,
              href: subChild.seoUrlKeywords,
              description: subChild.displayName,
              imagesCollection: null,
              _content_type_uid: 'mega_menu_item',
            }
          }),
          _content_type_uid: 'mega_menu_item',
        }
      }),
      _content_type_uid: 'mega_menu',
    }
  })
}
