import AtgMegaMenuDesktop, { Category } from 'atg/components/mega-menu'

type TasticProps = {
  data: {
    rootCategories: {
      dataSource: {
        ok: boolean
        dataSourceId: string
        rootCategories: Category[]
      }
    }
  }
}
const AtgMegaMenuTastic: React.FC<TasticProps> = ({ data }) => {
  const megaMenuData = data.rootCategories.dataSource?.rootCategories

  return <AtgMegaMenuDesktop megaMenuData={megaMenuData} />
}
export default AtgMegaMenuTastic
